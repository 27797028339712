html, body {
    padding: 0;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    font-family: 'Source Code Pro', monospace;
}

#app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#app-container {
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

#app-container > section {
    border: 1px dashed grey;
    padding: 10px;
}

#game-grid {
    display: flex;
    gap: 5px;
    width: 100%;
    flex-direction: column;
}

#game-grid .row {
    display: flex;
    gap: 5px;
}

#game-grid .cell {
    width: calc(100% / 5);
    aspect-ratio: 1;
    border: 1px solid black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.misplaced {
    color: #c14621;
}

.correct {
    color: green;
}

.incorrect {
    color: grey;
}

#game-grid .cell[data-status="misplaced"] {
    background-color: #c14621;
}

#game-grid .cell[data-status="correct"] {
    background-color: green;
}

#game-grid .cell[data-status="incorrect"] {
    background-color: grey;
}
